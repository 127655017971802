import { House } from '@phosphor-icons/react/dist/ssr';
import { MenuItemLink } from '@/types/types';
import React, { Fragment } from 'react';
import IKLink from './material/IKLink';

type Breadcrumb = {
  title: string;
  link: MenuItemLink;
};

export default function PageBreadcrumbs({
  breadcrumbs,
  color,
  className,
}: {
  breadcrumbs: Breadcrumb[];
  color?: 'white' | 'charcoal';
  className?: string;
}) {
  const textColor = color === 'white' ? 'text-white' : 'text-charcoal';
  const strokeColor = color === 'white' ? 'stroke-white' : 'stroke-charcoal';

  return (
    <div
      className={`flex flex-wrap items-center gap-1 pb-4 md:pb-0 ${className}`}
    >
      {breadcrumbs &&
        breadcrumbs.length > 0 &&
        breadcrumbs.map((breadcrumb: Breadcrumb, index: number) => (
          <Fragment key={index}>
            {index < breadcrumbs.length - 1 ? (
              <>
                {breadcrumb.link && (
                  <>
                    {breadcrumb.link.document === 'home' ? (
                      <IKLink
                        aria-label='homepage-link'
                        className={`group relative mb-[1px] text-sm underline-offset-4 hover:underline ${textColor}`}
                        link={breadcrumb.link}
                      >
                        <House
                          className={`h-4 w-4 opacity-100 group-hover:opacity-0 ${strokeColor}`}
                        />
                        <House
                          weight='fill'
                          className={`absolute left-0 top-0 h-4 w-4 opacity-0 group-hover:opacity-100 ${strokeColor}`}
                        />
                      </IKLink>
                    ) : (
                      <IKLink
                        aria-label={breadcrumb.title}
                        className={`text-sm underline-offset-4 hover:underline ${textColor}`}
                        link={breadcrumb.link}
                      >
                        {breadcrumb.title}
                      </IKLink>
                    )}
                  </>
                )}
              </>
            ) : (
              <span className={`text-sm ${textColor}`}>{breadcrumb.title}</span>
            )}
            {index < breadcrumbs.length - 1 && <span>{`/`}</span>}
          </Fragment>
        ))}
    </div>
  );
}
